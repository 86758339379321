<template>
  <div class="auth-modal">
    <component
      :is="regStepToComponentMap[registrationStep]"
      :external-registration-data="externalRegistrationData"
      :after-login-callback="afterLoginCallback"
      :after-registration-callback="afterRegistrationCallback"
      @next-step="nextStep()"
      @back-step="backStep()"
      @authorize="authorize()"
    />
  </div>
</template>

<script setup lang="ts">
  import {
    ModalLogin,
    ModalRegistrationStep1,
    ModalRegistrationStep2,
    ModalRegistrationStep3,
    ModalRegistrationStep4,
  } from '#components';
  import type {Component} from 'vue';
  import type {ExternalRegistrationDataValue} from '~/stores/externalRegistration';
  import {RegistrationStepEnum} from '~/stores/registration';

  const {t} = useI18n();

  const {afterLoginCallback, afterRegistrationCallback} = defineProps<{
    afterLoginCallback?: () => undefined;
    afterRegistrationCallback?: () => undefined;
    externalRegistrationData: ExternalRegistrationDataValue;
  }>();

  const regStepToHeaderMap: Partial<Record<RegistrationStepEnum, string>> = {
    [RegistrationStepEnum.Start]: t(
      'components.Modals.ModalAuth.ModalRegistration.title',
    ),
    [RegistrationStepEnum.Complete]: t(
      'components.Modals.ModalAuth.ModalRegistration.confirmation_title',
    ),
  };

  const regStepToComponentMap: Record<RegistrationStepEnum, Component> = {
    [RegistrationStepEnum.Start]: ModalRegistrationStep1,
    [RegistrationStepEnum.Confirm]: ModalRegistrationStep2,
    [RegistrationStepEnum.Complete]: ModalRegistrationStep3,
    [RegistrationStepEnum.End]: ModalRegistrationStep4,
    [RegistrationStepEnum.Login]: ModalLogin,
  };

  const useRegStore = useRegistrationStore();
  const {registrationStep} = storeToRefs(useRegStore);
  const {setRegistrationStep} = useRegStore;

  const useDialog = useDialogStore();
  const dialogRef = useDialog.getDialogRef();

  onMounted(() => {
    if (dialogRef?.options?.props) {
      dialogRef.options.props.header =
        regStepToHeaderMap[registrationStep.value];
    }
  });

  onUnmounted(() => {
    setRegistrationStep(RegistrationStepEnum.Start);
  });

  function authorize() {
    setRegistrationStep(RegistrationStepEnum.Login);

    dialogRef!.options.props!.header = t(
      'components.Modals.ModalAuth.signInDialog_title',
    );
  }

  function nextStep() {
    registrationStep.value++;

    dialogRef!.options.props!.header =
      regStepToHeaderMap[registrationStep.value];
  }

  function backStep() {
    registrationStep.value--;

    dialogRef!.options.props!.header =
      regStepToHeaderMap[registrationStep.value];
  }
</script>

<style lang="scss" scoped></style>
