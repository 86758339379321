import {useToast} from 'primevue/usetoast';

export const useExecutorStore = defineStore('executorStore', () => {
  const toast = useToast();

  const useAccountProfile = useAccountProfileStore();

  const {convertAccountToServiceProvider} = useAccountProfile;

  async function becomeExecutor(callbackFn?: () => void) {
    const {status} = await convertAccountToServiceProvider();

    if (status.value === 'success') {
      // const {t} = useI18n();
      toast.add({
        severity: 'success',
        // detail: t('stores.executor.convertedToServiceProvider'), // EB: this somehow didn't work, so I hardcoded the czech translation here
        detail: 'Nyní můžete plnit zadání a vydělávat!',
        life: 3000,
      });

      callbackFn && callbackFn();
    }
  }

  function $reset() {}

  return {becomeExecutor, $reset};
});
