export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"hid":"Cookiebot","id":"Cookiebot","src":"https://consent.cookiebot.com/uc.js","data-cbid":"82eee2a7-97ea-4382-8eb1-4dc21b380ed6","data-blockingmode":"auto","async":true,"type":"text/javascript"},{"innerHTML":"\n            !function(f,b,e,v,n,t,s)\n            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n            n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n            n.queue=[];t=b.createElement(e);t.async=!0;\n            t.src=v;s=b.getElementsByTagName(e)[0];\n            s.parentNode.insertBefore(t,s)}(window, document,'script',\n            'https://connect.facebook.net/en_US/fbevents.js');\n            fbq('init', '421362587683305');\n            fbq('track', 'PageView');\n          "},{"innerHTML":"\n              (function(c,l,a,r,i,t,y){\n                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};\n                  t=l.createElement(r);t.async=1;t.src=\"https://www.clarity.ms/tag/\"+i;\n                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);\n              })(window, document, \"clarity\", \"script\", \"pc7lntadz0\");\n          "}],"noscript":[{"innerHTML":"<img height=\"1\" width=\"1\" style=\"display:none\" src=\"https://www.facebook.com/tr?id=421362587683305&ev=PageView&noscript=1\"/>"}],"title":"GetJob"}

export const appRootId = "root"

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#root'

export const viewTransition = false