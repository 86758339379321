import {ref, onMounted, onUnmounted} from 'vue';

export type PageScrolledEventHandler = (name: number) => void;

function trackPageScroll(pageName: string, thresholds?: number[]) {
  thresholds = thresholds?.sort((a, b) => a - b);
  thresholds ??= [25, 50, 75, 100];

  const scrollDepth = ref(0);
  const maxReachedScrollDepth = ref(0);
  const maxSentThreshold = ref(0);

  const handleScroll = () => {
    const scrollHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = Math.ceil((window.scrollY / scrollHeight) * 100);
    scrollDepth.value = Math.min(100, scrolled);

    if (scrollDepth.value > maxReachedScrollDepth.value) {
      maxReachedScrollDepth.value = scrollDepth.value;

      for (let index = 0; index < thresholds.length; index++) {
        const thresholdValue = thresholds[index];
        if (thresholdValue > scrollDepth.value) {
          break;
        }

        if (thresholdValue > maxSentThreshold.value) {
          trackEvent(`Page_${pageName}_Scrolled_${thresholdValue}_Percent`);
          maxSentThreshold.value = thresholdValue;
        }
      }
    }
  };

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });
}

function trackEvent(eventName: string, eventProps?: object) {
  if (process.env?.NODE_ENV !== 'Development') {
    window?.fbq('trackCustom', eventName, eventProps);
  } else {
    console.log('tracking event: ', eventName, ' with props: ', eventProps);
  }
}

export function useTracking() {
  return {
    trackPageScroll,
    trackEvent,
  };
}
